.title {
    font-family: CrimsonText_Bold, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 112.433%;
    color: #FCFAF9;
    text-align: center;
    max-width: 559px;
    width: 100%;
    margin: 0 auto 30px;
}

.text {
    text-align: center;
    color: #FCFAF9;
    font-family: Roboto_Regular, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 720px;
    width: 100%;
    margin: auto;
}

.arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    cursor: pointer;
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .title {
        font-size: 62px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .title {
        font-size: 48px;
    }
}

@media only screen and (min-width: 425px)  and (max-width: 767px) {
    .title {
        font-size: 38px;
    }
}

@media only screen  and (max-width: 424px) {
    .title {
        font-size: 32px;
    }
}
