.headerWhole {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.iconStyle {
    cursor: pointer;
}

.logoStyle {
    cursor: pointer;
}

@media only screen  and (max-width: 767px) {
    .logoStyle {
        width: 180px;
    }
}