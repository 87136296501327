.title {
    font-family: Roboto_Regular, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FCFAF9;
}

.boldTitle {
    font-family: Roboto_Bold, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    color: #FCFAF9;
    margin-bottom: 30px;
    /*margin-top: -12px;*/
}

.text {
    font-family: Roboto_Regular, sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 300;
    line-height: 150%;
    margin-bottom: 30px;
    color: #9D9D9C;
}

.startBtn {
    padding: 4px 11px;
    font-family: Roboto_Regular, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #000;
    background-color: #9D9D9C;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.arrowImg {
    margin-left: 12px;
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .boldTitle {
        font-size: 62px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .boldTitle {
        font-size: 48px;
    }
}

@media only screen and (min-width: 425px)  and (max-width: 767px) {
    .boldTitle {
        font-size: 38px;
    }
}

@media only screen  and (max-width: 424px) {
    .boldTitle {
        font-size: 32px;
    }
}