.layout {
    background: #000;
    padding: 50px 72px;
    width: 100%;
    height: 100vh;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 24px;
    height: 100%;
    padding-bottom: 48px;
}

.inside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*gap: 250px;*/
}

h2 {
    font-family: Roboto_Regular, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #FCFAF9;
    margin-bottom: 20px;
}

.boldTitle {
    color: #FCFAF9;
    font-family: Roboto_Bold, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.boldNumber {
    color: #FCFAF9;
    font-family: Roboto_Bold, sans-serif;
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.downloadText {
    color: #FCFAF9;
    font-family: Roboto_Bold, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 29px;
}

.below {
    display: flex;
    align-items: center;
    gap: 50px;
}

.downloadBtn {
    background-color: #9D9D9C;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 16px;
    font-family: Roboto_Regular, sans-serif;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    height: 38px;
}

.line {
    width: 2px;
    height: 100px;
    background-color: #9D9D9C
}

.inputAndSend {
    display: flex;
}

.send {
    cursor: pointer;
    padding: 10px 20px;
    background: #9D9D9C;
    font-family: Roboto_Regular, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: #000;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

input {
    background: #EEF2F5;
    height: 38px;
    width: 245px;
    color: #000;
    font-family: Roboto_Regular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.56px;
    padding-left: 20px;
}

input::placeholder {
    color: #AAAAAA;
    font-family: Roboto_Regular, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

/*.right {*/
/*    max-width: 800px;*/
/*    width: 100%;*/
/*    height: 450px*/
/*}*/

.rightContent {
    max-width: 800px;
    width: 100%;
    height: 450px
}

.errorDiv {
    padding-top: 30px;
}

.error {
    color: red;
    margin-top: 10px;
}

.todayDate {
    max-width: 800px;
    width: 100%;
    color: white;
    text-align: center;
    margin-bottom: 12px;
    font-family: Roboto_Bold, sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
}

@media only screen and (min-width: 1701px) and (max-width: 1920px) {
    .rightContent {
        max-width: 700px;
    }
}


@media only screen and (min-width: 1440px) and (max-width: 1700px) {
    .rightContent {
        max-width: 600px;
    }
}


@media only screen and (min-width: 1024px) and (max-width: 1440px) {
    /*.inside {*/
    /*    gap: 70px*/
    /*}*/
    .rightContent {
        max-width: 500px;
    }
}

@media only screen and (max-width: 1024px) {
    .layout {
        padding: 24px
    }

    .below {
        gap: 20px
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .boldTitle {
        font-size: 62px;
    }

    .boldNumber {
        font-size: 62px;
        margin-top: 20px;
    }

    h2 {
        font-size: 62px;
        margin-top: 40px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .inside {
        gap: 200px;
    }

    .downloadText {
        font-size: 22px;
    }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .boldTitle {
        font-size: 48px;
    }

    .inside {
        gap: 100px;
    }

    .boldNumber {
        font-size: 48px;
    }

    h2 {
        font-size: 48px;
        margin-top: 20px;
    }

    .downloadText {
        font-size: 22px;
    }
}

@media only screen and (min-width: 425px)  and (max-width: 767px) {
    .boldTitle {
        font-size: 38px;
    }

    .boldNumber {
        font-size: 38px;
    }

    h2 {
        font-size: 38px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 424px) {
    .boldTitle {
        font-size: 32px;
    }

    .boldNumber {
        font-size: 32px;
    }

    h2 {
        font-size: 32px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 1024px) {
    .line {
        display: none;
    }

    .below {
        flex-direction: column;
        align-items: unset;
    }

    .downloadBtn {
        max-width: fit-content;
    }

    h2 {
        margin-top: 20px !important;
    }
}

@media only screen and (max-width: 1023px) {
    .layout {
        height: auto;
    }

    .inside {
        flex-direction: column-reverse;
        gap: 0;
        align-items: unset;
    }

    .main {
        padding-bottom: 22px;
    }

    .rightContent {
        margin: 0 auto 24px;
        height: unset;
    }
}

@media only screen and (max-width: 374px) {
    input {
        width: 200px;
    }
}